require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landing', undefined);

  },
  /* handler:onClick *//* content */
}));

define('./pages/landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var funcionName, isMobile0001, containerName0002, containerUrl0002, hostName0002, todayNames0006, todayNamesListJSON0006, item, todayNamesList0006, today0006, allNames0006;

/**
 * Describe this function...
 */
async function my_02___appWay() {
  if (false) {
  }
}

/**
 * Describe this function...
 */
async function my_00_01___checkMobile() {
  isMobile0001 = (((function () { return navigator.userAgent })()).indexOf('Mobile') !== -1) ? true : false;
  ___arguments.context.appData['isMobile0001'] = isMobile0001;
}

/**
 * Uruchamia główne testy przed uruchomieniem
 */
async function my_00___startAppData() {
  ___arguments.context.appData['visibleSpace'] = 0;
  ___arguments.context.appData['uLocation'] = ({ [`lat`]: 0,[`lng`]: 0 });
  await my_00_01___checkMobile();
  await my_00_02___checkUrl();
  await my_00_06___names();
  await (async function() {
  	function showOrientationOverlay() {
  	  var overlay = document.createElement("div");
  	  overlay.id = "orientation-overlay";
  	  overlay.style.position = "fixed";
  	  overlay.style.top = "0";
  	  overlay.style.left = "0";
  	  overlay.style.width = "100%";
  	  overlay.style.height = "100%";
  	  overlay.style.backgroundColor = "rgba(0, 0, 0, 1)";
  	  overlay.style.display = "flex";
  	  overlay.style.alignItems = "center";
  	  overlay.style.justifyContent = "center";
  	  overlay.style.zIndex = "99999";
  	
  	  var message = document.createElement("span");
  	  message.style.color = "#ffffff";
  	  message.style.fontSize = "24px";
  	  message.style.fontFamily = "Arial, sans-serif";
  	  message.style.textAlign = "center";
  	  message.textContent = "www.wmojejokolicy.pl";
  	  overlay.appendChild(message);
  	
  	  document.body.appendChild(overlay);
  	}
  	
  	function hideOrientationOverlay() {
  	  var overlay = document.getElementById("orientation-overlay");
  	  if (overlay) {
  	    overlay.parentNode.removeChild(overlay);
  	  }
  	}
  	
  	window.addEventListener("orientationchange", function() {
  	  if (Math.abs(window.orientation) === 90) {
  	    showOrientationOverlay();
  	  } else {
  	    hideOrientationOverlay();
  	  }
  	});
  	
  	// Sprawdź orientację strony przy starcie
  	if (Math.abs(window.orientation) === 90) {
  	  showOrientationOverlay();
  	} else {
  	  hideOrientationOverlay();
  	}

  })();
  await (async function() {
  	function showLoadingScreen() {
  	  var loadingScreen = document.createElement('div');
  	  loadingScreen.style.position = 'fixed';
  	  loadingScreen.style.top = '0';
  	  loadingScreen.style.left = '0';
  	  loadingScreen.style.width = '100%';
  	  loadingScreen.style.height = '100%';
  	  loadingScreen.style.backgroundColor = '#000000';
  	  loadingScreen.style.display = 'flex';
  	  loadingScreen.style.flexDirection = 'column';
  	  loadingScreen.style.alignItems = 'center';
  	  loadingScreen.style.justifyContent = 'center';
  	  loadingScreen.style.zIndex = '9999';

  	  var logo = document.createElement('img');
  	  logo.src = './mimg/logo/wmo_logo_300x300.png';
  	  logo.alt = 'Logo';
  	  logo.style.width = '100px';
  	  logo.style.height = '100px';

  	  var loadingText = document.createElement('p');
  	  loadingText.textContent = 'Przeszukuję okolicę...';
  	  loadingText.style.color = '#ffffff';

  	  loadingScreen.appendChild(logo);
  	  loadingScreen.appendChild(loadingText);

  	  document.body.appendChild(loadingScreen);

  	  // Symulacja opóźnienia ładowania
  	  setTimeout(function() {
  	    loadingScreen.style.display = 'none';
  	    // Wyświetl aplikację
  	  }, 3000); // Symulowane opóźnienie ładowania: 3000 ms (3 sekundy)
  	}

  	// Wywołanie funkcji
  	showLoadingScreen();

  })();
  await (async function() {
  	const resutl = await BackendlessUI.requireModule('https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js')

  	const el = document.querySelector('.animation')

  	el.innerHTML = `
  	<lottie-player src="./mimg/lottie/logo_wmo_v1.json"  background="white"  speed="1"  style="width: 100px; height: 100px;"  loop  autoplay></lottie-player>
  	`

  })();
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function my_00_02___checkUrl() {
  if (((function () { return window.location.href })()).indexOf('backendless') !== -1) {
    containerName0002 = (await (async function(inputText, textBefore, textAfter) {
    	    const startIndex = inputText.indexOf(textBefore);
    	    if (startIndex === -1) {
    	        return ''; // Tekst przed nie został znaleziony
    	    }
    	
    	    const endIndex = inputText.indexOf(textAfter, startIndex + textBefore.length);
    	    if (endIndex === -1) {
    	        return ''; // Tekst po nie został znaleziony
    	    }
    	
    	    const extractedText = inputText.substring(startIndex + textBefore.length, endIndex);
    	    return extractedText;
    })(((function () { return window.location.href })()), '/containers/', '/index'));
    hostName0002 = (getObjectProperty(((function () { return window.location })()), 'host'));
    containerUrl0002 = ['https://',hostName0002,'/api/files/ui-builder/containers/'].join('');
    ___arguments.context.appData['app0002'] = ({ [`Url`]: [containerUrl0002,containerName0002,'/'].join(''),[`UrlJsonHttps`]: [containerUrl0002,containerName0002,'/json/'].join(''),[`isMobile`]: isMobile0001,[`containerName`]: containerName0002 });
  } else {
    ___arguments.context.appData['app0002'] = ({ [`Url`]: ((function () { return window.location.href })()),[`UrlJsonHttps`]: String((function () { return window.location.href })()) + String('/json/'),[`isMobile`]: isMobile0001,[`containerName`]: containerName0002 });
  }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function my_00_06___names() {
  today0006 = [String(new Date((new Date())).getDate()),'.',String(new Date((new Date())).getMonth() + 1)].join('');
  allNames0006 = (JSON.parse((await (async function(secretKey, encryptedData) {
  	  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  	  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  	  return JSON.parse(decryptedData);

  })((getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'keyLS')), (await (async function(fileURL) {
  	// async function fetchTextFile(fileURL) {
  	  try {
  	    const response = await fetch(fileURL);
  	
  	    if (!response.ok) {
  	      throw new Error('Błąd pobierania pliku.');
  	    }
  	
  	    const data = await response.text();
  	    return data;
  	  } catch (error) {
  	    throw error;
  	  }
  	// }

  })((String(getObjectProperty(___arguments.context.appData, 'app0002.UrlJsonHttps')) + String('names_S.json'))))))));
  todayNames0006 = (await asyncListFilter(allNames0006, async (item) => {
     item = String(getObjectProperty(item, 'day')) == today0006;


   return item;
  }));
  todayNamesList0006 = (getObjectProperty((getObjectProperty(todayNames0006, '0')), 'names')).split(',');
  todayNamesListJSON0006 = (await Promise.all(todayNamesList0006.map(async item => {; return ({ [`name`]: item });})));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d60303fdccc180ece293b9c571c5c2e9', todayNamesListJSON0006);
}


  await my_00___startAppData();
  await my_02___appWay();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/landing/components/48dffcc74945f03d561f67aa394799a4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'checked')) != 1)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/67781a3a8427297effc673cb4b7ad576/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'checked')) != 1 && ((function(){ try { return JSON.parse(localStorage.getItem('exist')) } catch(e){ return null }})()) == null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/64b22f2e967708cd2f23f0118fe71b96/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      return ({ [`backgroundImage`]: 'url(./mimg/forest-g1fa903334_1280.jpg)' })

  },
  /* handler:onStyleAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'visibleSpace')) == 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/b1f1629456a9a28100aa2f673b9aadf6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.appData['visibleSpace'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/1a76f56c1028f4fc66b762050e764272/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'visibleSpace')) == 1)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/8ce5cdffbba676dd20c8cb11362b7fa1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.appData['visibleSpace'] = 0;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/30b7f78c9273cf82a4f7a6cbd6bff57b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var userLng, userLat, uLocation;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  uLocation = (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })());
  userLat = (Number((getObjectProperty(uLocation, 'lat'))));
  userLng = (Number((getObjectProperty(uLocation, 'lng'))));
  ___arguments.context.appData['uLocation'] = ({ [`lat`]: userLat,[`lng`]: userLng });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f30436620b3cda33a6fc868ff40dddbf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'isMobile')) == true)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/726809df75014c5dc6843cfdb54501b3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var m, onona;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'name')).endsWith('a')) {
    onona = '#FF00FF';
  } else {
    onona = '#0000FF';
  }

  return ({ [`backgroundColor`]: onona })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

